import React, {useState} from "react";
import {STRINGS} from "../../constants/strings";
import {SubscribePopup, track} from '@cg-squad/ui-components';
import {metadata} from '../../../config'
import mixpanel from "../../constants/mixpanel";

function SubscribePopupWrapper(props) {
    const [loading, setLoading] = useState(false);

    const onClosePopup = (closeType) => {
      switch(closeType) {
        case 'cross':
          track(mixpanel.MIXPANEL_SUBSCRIBE_FLOW.SUBSCRIBE_POPUP_CLOSE);
          break;
        case 'cancel':
          track(mixpanel.MIXPANEL_SUBSCRIBE_FLOW.SUBSCRIBE_POPUP_CANCEL);
          break;
        case 'api':
          break;
        default:
          track(mixpanel.MIXPANEL_SUBSCRIBE_FLOW.SUBSCRIBE_POPUP_BACKDROP);
      }
      props.onPopupClose();
    }

    const logoConf = {
        img: 'pt-white.svg',
        width: 300,
        height: 80
    }

    const subscribeApi = (data) => {
        setLoading(true);
        try {
            track(mixpanel.MIXPANEL_SUBSCRIBE_FLOW.SUBSCRIBE_POPUP_SUBMIT);
        } catch (e) {
            console.log(e);
        }
        fetch(`https://api.agegroup.io/subscribe?name=${data.name}&email=${data.email}&source=${window.location.pathname}&marketing=${data.marketing}&website=${STRINGS.SUBSCRIPTION_TAG}`)
            .then(resp => resp.json())
            .then(res => {
                setLoading(false);
                if (res.error) {
                  alert(res.error.msg);
                } else {
                  alert('Successfully subscribed to our newsletter');
                  onClosePopup('api');
                }
            });
    }

    return (
        <SubscribePopup pageName={metadata.defaultTitle}
                        onPopupClose={onClosePopup}
                        subscribe={subscribeApi}
                        loading={loading}
                        logoConf={logoConf}
                        containerClass="bg-denim-800"/>
    );
}

export default SubscribePopupWrapper
